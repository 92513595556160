import './vendors/h5bp';

/* 
 * - Mapa mediante la API de Google Maps
 *
 */

function googleMaps() {
  
  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.737220, -60.507916);

  // Estilos
  var estilos = [ { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },{ "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#e52d27" } ] },{ "featureType": "water", "stylers": [ { "color": "#e52d27" } ] } ];

  // Opciones del mapa
  var mapOptions = {
    center: lugar,
    zoom: 15,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    // icon: "../assets/media/location-icon.png",
    map: map,//lo asocio al mapa
    optimized: false
  });

  var infoWindow = new google.maps.InfoWindow({
      content: "<p style='text-align:center'>Padre Bartolomé Grella 1349 <br> C.P. 3100 <br> Paraná | Entre Ríos</p>"
  });

  // google.maps.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker);
  // });

}

/* 
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */

function contactFormGo(){

  $("#contact-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('input,textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#contact-name').val(),
        telefono: $('#contact-tel').val(),
        telefono2: $('#contact-tel2').val(),
        email: $('#contact-email').val(),        
        mensaje: $('#contact-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $(".button-collapse").sideNav();
}


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
 function goSliders() {

  $(".img-slider").slick({
    arrows: true,
    dots: true,
    autoplay: true
  });

  $(".latest-news").slick({
    arrows: false,
    dots: true,
    autoplay: true
  });

}

/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function(){

  goSliders();
  goMaterialize();

  if($("#map-canvas").length){
    contactFormGo();
    googleMaps();
  }

});
        
